.panel-section.search {
    background: $mid_grey;
    form {
        text-align: center;
        input, select {
            width: 100%;
            border: 1px solid #333;
            padding: 5px 10px;
            background: $mid_grey;
            margin-bottom: 20px;
            transition: all .2s linear;
            &:focus {
                outline: none;
                background: $light_grey;
            }
        }
        .button {
            width: auto;
            border: 1px solid $red;
            color: $red;
            padding: 5px 40px;
            margin: 0 auto;
            display: inline-block;
            background: $mid_grey; 
            transition: all .2s linear;
            &:hover {
                background: $red;
                color: #FFF;
            }
        }
        @include breakpoint(alpha) {
            label {
                @include font-size(14);
            }
        }
    }
}

.search-results {
    padding-top: 50px !important;
    h4 {
        color: $red;
        letter-spacing: 5px;
        text-align: center;
        font-family: $default_body_font;
        @include font-size(24);
        margin: 25px 0 8px !important;
    }
    .result {
        min-height: 500px;
        .thumbnail {
            min-height: 245px;
            border: none;
            padding: 0;
            @include breakpoint(alpha) {
                max-width: 400px; 
                margin: 0 auto;
            }
        }
        @include breakpoint(alpha) {
            padding-top: 50px;   
        }
        @include breakpoint(gamma) {
            min-height: inherit;
            padding: 0 0 35px 0;
        }
    }
    .info {
        margin-top: 15px;
        text-align: center;
        @include font-size(14);                
        & > span {
            display: block;
            min-height: 40px;
            padding: 0 30px;
        }
        .buttons {
            margin-top: 25px;
            a {
                display: inline-block;
                border: 1px solid $red;
                color: $red;
                padding: 5px 25px;
                transition: all .2s linear;
                &:hover {
                    text-decoration: none;
                    background: $red;
                    color: #FFF;
                }
            }
        }
    }
}

.pagination {
    display: block;
    margin: 0;
    border-radius: 0;
    text-align: center;
    div {
        display: inline-block;
        margin: 0 10px;
        &.active {
            font-weight: bold;
        }
    }
    @include breakpoint(alpha) {
        margin-top: 50px;    
    }
}

.panel-section.property {
    .buttons .ghost {
        background: #FFF;
        border: 1px solid $red;
        color: $red;
        transition: all .2s linear;
        float: left;
        margin: 0 0 25px;
        &:hover {
            text-decoration: none;
            background: $red;
            color: #FFF;
        }
        &:last-of-type {
            float: right;
        }
        @include breakpoint(gamma) {
            float: none;
            width: 100%;
            text-align: center;
        }
    }
}

.panel-section.details {
    padding-top: 0;
    .feature {
        margin-bottom: 30px;
        p {
            @include font-size(14); 
            max-width: 800px;
        }
        ul {
            margin-left: 15px;
            li {
                @include font-size(14); 
                list-style: disc;
            }
        }
    }
    h4 {
        text-align: left;
        color: #333;
        margin-bottom: 15px;
        @include breakpoint(gamma) {
            @include font-size(20);    
        }
    }
    .accommodation {
        min-width: 450px;
        max-width: 800px;
        td, th {
            padding: 5px 25px;
            @include font-size(14); 
            color: #666;
            text-align: center;
            &:first-of-type {
                text-align: left;
            }
        }
        tr th {
            font-weight: bold;
            color: #333;
        }
        tr {
            border-top: 1px solid #CCC;
        }
        tfoot td {
            font-weight: bold;
            background: $mid_grey;
            border-bottom: 1px solid #CCC;
        }
        @include breakpoint(gamma) {
            min-width: inherit;    
        }
    }
    .contact {
        text-align: center;
        padding-bottom: 15px;
        h4 {
            color: $red;
            letter-spacing: 5px;
            text-align: center;
            font-family: $default_body_font;
            @include font-size(20);
            margin: 15px 0 8px;
        }
        span {
            @include font-size(14);         
        }
        .ghost {
            background: #FFF;
            border: 1px solid $red;
            color: $red;
            transition: all .2s linear;
            padding: 6px 10px;
            margin: 10px 0 25px;
            @include font-size(14);         
            &:hover {
                text-decoration: none;
                background: $red;
                color: #FFF;
            }
        }
        @include breakpoint(grid-sm) {
            img {
                max-width: 250px;               
            }   
        }
    }
    @include breakpoint(gamma) {
        .ghost {
            width: 100%;
            margin: 0 0 30px 0;
            text-align: center;
        }   
    }
}

