main .panel-section {
    .addresses {
        max-width: 750px;
        margin: 0 auto;
        .address {
            text-align: center;
            padding-bottom: 50px;
            &.liverpool {
                display: none;
                @media screen and ( min-width: 767px ) {
                    display: block !important;                    
                }
            }
            @include breakpoint(grid-xs) {
                display: none;
                &.active {
                    display: block;
                }
            }
            @include breakpoint(gamma) {
                padding-bottom: 25px;
            }
        }
        .button {
            display: block;
            width: 100%;
            max-width: 70%;
            padding: 10px 45px;
            margin: 0 auto 25px;
            border-radius: 4px;
            border: 1px solid #C5C5C5;
            text-decoration: none !important;
            text-align: center;
            @include font-size(20);
            &.active {
                border-color: $red;
                color: $red;
            }
            @include breakpoint(beta) {
                max-width: none;
            }
        }
    }
    &.map {
        padding: 0;
        .gmap {
            display: none;                       
            &.active {
                display: block;
            }
            & > div {
                width: 100%;
                max-height: 600px;
                height: 43vw;
                @include breakpoint(gamma) {
                    height: 300px;    
                }
            }
        }
    }
    &.contacts {
        .office {
            display: none;
            &.active {
                display: block;
            }
        }
        .key-contacts .kc {
            min-height: 400px;
            @include breakpoint(grid-sm) {
                max-width: none;   
                min-height: inherit;
                .contacts {
                    margin-bottom: 25px;
                    word-wrap: break-word;
                }
            }
            @include breakpoint(gamma) {
                min-height: 322px; 
                h4 {
                    @include font-size(18);
                }
            }
        }
    }
    .get-in-touch {
        max-width: 500px;
        margin: 0 auto;
        h2 {
            letter-spacing: 0;
            font-weight: bold;
            margin-bottom: 10px;
            @include font-size(22); 
            color: #666;
        }
        label {
            display: block;
            @include font-size(14);
        }
        input, textarea {
            width: 100%;
            margin: 0 0 15px;
            background: #FFF;
            border: 1px solid #CCC;
            padding: 8px 15px;
            outline: none !important;
            @include font-size(14);
        }
        textarea {
            height: 100px;    
        }
        .button {
            width: auto;
            border: 1px solid $red;
            color: $red;
            padding: 5px 40px;
            margin: 0 auto;
            display: inline-block;
            background: #FFF; 
            transition: all .2s linear;
            &:hover {
                background: $red;
                color: #FFF;
            }
            @include breakpoint(gamma) {
                display: block;
                text-align: center;
                width: 100%;
            }
        }
    }
}

.key-contacts .row {
    display: flex;
    flex-wrap: wrap;
    @include breakpoint(gamma) {
        .kc:first-child, .kc:last-child {
            flex: 1;
        }
    }
}