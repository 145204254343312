// Media query breakpoints
$breakpoints: (
	
    'alpha'     :   ( max-width: 1030px ), 
    'grid-sm'   :   ( max-width: 992px ),
    'beta'	    :   ( max-width: 680px ),
    'grid-xs'	:   ( max-width: 600px ),
    'gamma'	    :   ( max-width: 575px ),
    'wrapper'   :   ( max-width: $max_width ),    

);

// Responsive styles
@mixin breakpoint($name) {
	@if map-has-key($breakpoints, $name) {
    	@media screen and #{inspect(map-get($breakpoints, $name))} {
      		@content;
    	}
  	} @else {
    	@warn "Couldn't find a breakpoint named `#{$name}`.";
  	}
}

.dont-break-out {

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

}

