// HTML font sizing for rems, 62.5% so we can use 14px/1.4rem, etc.
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville|Open+Sans');

html { 
    font-size: 62.5%; 
}

body { 
	@include font($default_font_size);
	-webkit-font-smoothing: antialiased;
	color: $default_font_colour;
    font-weight: 300;
}

p {
	margin: 0 0 1em 0;
    line-height: 150%;
    font-weight: 300;
	&:last-of-type { margin-bottom: 0; }
}

ul { 
    padding: 0; 
}

li {
    margin: 0;
	line-height: 150%;
	list-style-type: none;
    font-weight: 300;
}

th, td {
    font-weight: 300;
}

h1, h2, h3, h4, h5 {
	@include font(16, $default_heading_font, normal, 400);
    margin: 0;    
}

h1 { @include font_size(36); }
h2 { @include font_size(26); }

a {
    color: $grey;
    transition: color .25s linear;
    &:hover {
        color: darken($grey, 25%);
    }
}