main {
    .client {
        margin-bottom: 25px;
    }
    .client-requirements {
        max-width: 850px;
        margin: 0 auto;
        .client-image {
            float: left;
            width: 200px;
            height: 200px;
            padding: 40px;
            // display: flex;
            display: block; // Fix for IE
            align-items: center;
            text-align: center;
            background: #F8F8F8;
            border: 1px solid #C5C5C5;
            border-right: none;
            img {
                max-width: 100%;
                margin: 0 auto;
            } 
            @include breakpoint(grid-xs) {
                float: none;
                width: auto;
                height: auto;
                max-width: 325px;
                margin: 0 auto;
                border-right: 1px solid #C5C5C5;
                border-bottom: none;
                padding: 15px;
            }
        }
        .client-tabs {
            width: 100%;
            width: calc(100% - 200px);
            min-height: 200px;
            float: right;
            border-left: 1px solid #C5C5C5;
            .nav-tabs {
                border-bottom-color: #C5C5C5;
                & > li { 
                    &.active > a{
                        background: #ECECEC;
                    }
                    & > a {
                        padding: 20px 35px;
                        min-height: 60px;
                        margin-right: 0;
                        border-radius: 0;
                        border: 1px solid #C5C5C5;
                        border-left: none;
                        line-height: 100%;
                        @include font-size(18);
                        @include breakpoint(beta) {
                            @include font-size(16);
                            padding: 15px 25px;
                            min-height: 48px;
                        }
                        @include breakpoint(grid-xs) {
                            border-top: none;
                            text-align: center;
                        }
                        @include breakpoint(gamma) {
                            padding: 15px 15px;
                        }
                    }
                    @include breakpoint(grid-xs) {
                        width: 50%;
                    }
                }
                @include breakpoint(grid-xs) {
                    border-top: 1px solid #C5C5C5;
                }
            }
            .tab-content {
                border-right: 1px solid #C5C5C5;
                border-bottom: 1px solid #C5C5C5;
                padding: 25px 35px;
                min-height: 140px;
                ul {
                    margin-left: 1em;
                    li {
                        list-style-type: disc;
                        @include font-size(14);
                    }
                }
                @include breakpoint(beta) {
                    min-height: 152px;  
                }
                @include breakpoint(gamma) {
                    min-height: inherit;    
                }
            }
            .contacts {
                img {
                    max-width: 120px;
                }
                h4 {
                    color: $red;
                    letter-spacing: 4px;
                    text-align: left;
                    font-family: $default_body_font;
                    @include font-size(16);
                    margin: 15px 0 10px 0;
                }
                .contacts {
                    margin: 0 0 25px 0;
                    @include font-size(12);
                    word-wrap: break-word;
                }
                @include breakpoint(gamma) {
                    div[class*="col-"] {
                        &, h4 {
                            text-align: center;
                        }
                        &:last-child .contacts {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            @include breakpoint(grid-xs) {
                float: none;
                width: auto;
            }
            @include breakpoint(gamma) {
                min-height: inherit;    
            }
        }
        @include breakpoint(grid-sm) {
            max-width: none;
            padding: 0 25px;
        }
    }
    .client-logos {
        max-width: 850px;
        margin: 0 auto;
        figure {
            display: flex;
            align-items: center;
            height: 15vw;
            max-height: 190px;
            background: #F8F8F8;
            margin-bottom: 30px;
            img {
                max-width: 90%;
                max-height: 90%;
                margin: 0 auto;
            }
        }
        @include breakpoint(gamma) {
            padding: 0 25px;
            figure {
                height: 120px;
            }
        }
    }
    .button-wrapper {
        text-align: center;
        .button {
            display: inline-block;
            padding: 5px 15px;
            border-radius: 4px;
            border: 1px solid #C5C5C5;
            text-decoration: none !important;
            @include font-size(12);
            @include breakpoint(gamma) {
                width: 100%;
                @include font-size(16);
            }
        }
    }
}