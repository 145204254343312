main {
    .panel-section {
        padding: 50px;
        &:first-of-type {
            padding-top: 75px;
            @include breakpoint(beta) {
                padding-top: 50px;    
            }
        }
        &:last-of-type {
            padding-top: 0;
        }
        h2 {
            text-align: center;
            letter-spacing: 7px;
            font-family: $default_body_font;
            @include font-size(16);
            @include breakpoint(gamma) {
                @include font-size(14);            
            }
        }
        h3 {
            color: $red;
            text-align: center;
            margin: 10px auto 60px;
            max-width: 850px;
            @include font-size(70);
            @include breakpoint(grid-sm) {
                @include font-size(52);            
            }
            @include breakpoint(beta) {
                @include font-size(40);   
                margin-bottom: 35px;
            }
            @include breakpoint(gamma) {
                @include font-size(28);            
            }
        }
        h4 {
            font-family: $default_body_font;
            @include font-size(28);
            text-align: center;
            margin-bottom: 30px;
        }
        .paragraph-col {
            max-width: 750px;
            margin: 0 auto;
            h2 {
                text-align: left;
                letter-spacing: 0;
                font-weight: bold;
                margin-bottom: 8px;
                @include font-size(22); 
                color: #666;
            }
            p {
                @include font-size(18);
                line-height: 170%;
                @include breakpoint(grid-sm) {
                    @include font-size(16);            
                }
                @include breakpoint(gamma) {
                    @include font-size(14);            
                }
            }
            ul {
                margin: 1em 0 2em 2.5em;
                ul {
                    margin: .5em 0 .5em 2.5em;
                }
            }
            li {
                list-style-type: disc;
                line-height: 170%;
                margin-bottom: 4px;
                @include font-size(18);
                @include breakpoint(grid-sm) {
                    @include font-size(16);            
                }
                @include breakpoint(gamma) {
                    @include font-size(14);            
                }
            }
            @include breakpoint(grid-sm) {
                max-width: none;
            }
        }
        .centered .paragraph-col {
            text-align: center;
        }
        &.dark {
            margin: 50px 0;
            min-height: 250px;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("/images/about.jpg");
            // Mango Quote->title = class
            &.agency {
                background-image: url("/images/quote-bg/agency.jpg");
            }
            &.management {
                background-image: url("/images/quote-bg/management.jpg");
            }
            &.building-surveying {
                background-image: url("/images/quote-bg/building-surveying.jpg");
            }
            &.business-space {
                background-image: url("/images/quote-bg/business-space.jpg");
            }
            &.flexible-serviced-space {
                background-image: url("/images/quote-bg/flexible-serviced-space.jpg");
            }
            &.investment {
                background-image: url("/images/quote-bg/investment.jpg");
            }
            &.lease-advisory {
                background-image: url("/images/quote-bg/lease-advisory.jpg");
            }
            &.project-management {
                background-image: url("/images/quote-bg/project-management.jpg");
            }
            &.valuation {
                background-image: url("/images/quote-bg/valuation.jpg");
            }
            h3 {
                position: relative;                
                margin: 0 auto;
                max-width: 1000px;
                line-height: 150%;
                @include font-size(36);
                color: #FFF;
                &.quote {
                    padding: 80px 0;
                    &:before, &:after {
                        display: block;
                        position: absolute;
                        content: "";
                        width: 50px;
                        height: 50px;
                        left: calc(50% - 25px); 
                        background-size: 50px 50px;
                    }
                    &:before {
                        top: 0;
                        background-image: url("/images/quote_left.png");
                    }
                    &:after {
                        bottom: 0;
                        background-image: url("/images/quote_right.png");
                    }
                }
                @include breakpoint(grid-sm) {
                    @include font-size(26);            
                }
            }
            .stats {
                display: flex;
                flex-flow: row wrap;
                .stat {
                    flex: 1;
                    flex-basis: 50%;
                    h3 {
                        @include font-size(72);
                    }
                    h4 {
                        font-family: $default_heading_font;
                        color: $red;
                        margin-bottom: 10px;
                    }
                }
            }
            @include breakpoint(gamma) {
                margin: 25px 0;   
                h3 {
                    line-height: 150%;
                    @include font-size(24);
                    &.quote {
                        padding: 40px 0;
                        &:before, &:after {
                            width: 25px;
                            height: 25px;
                            left: calc(50% - 12px); 
                            background-size: 25px 25px;
                        }
                    }
                    @include breakpoint(grid-sm) {
                        @include font-size(18);            
                    }
                }
            }
        }
        
        .side-section {
            max-width: 870px;
            margin: 0 auto;
            padding: 35px 60px;
            border: 1px dashed $red;
            h4 {
                text-align: left;
                letter-spacing: 0;
                font-weight: bold;
                margin-bottom: 20px;
                @include font-size(22); 
                color: #666;
            }
            .button-surround {
                margin-top: 35px;
                float: left;
                width: 50%;
                h2 {
                    text-align: left;
                    letter-spacing: 0;
                    font-weight: bold;
                    margin-bottom: 16px;
                    @include font-size(18); 
                    color: #666;
                }
                .button {
                    display: inline-block;
                    padding: 8px 50px;
                    border-radius: 4px;
                    border: 1px solid #C5C5C5;
                    text-decoration: none !important;
                    @include font-size(14);
                }
            }
             @include breakpoint(grid-sm) {
                padding: 25px 35px;
                .button-surround {
                    float: none;
                    width: auto;
                    margin: 15px 0;
                    .button {
                        padding: 8px 25px;
                        display: block;
                        text-align: center;
                        width: 100%;
                    }
                }
            }
        }
        
        .key-contacts {
            h4, h5 {
                color: $red;
                letter-spacing: 3px;
                text-align: center;
                font-family: $default_body_font;
                margin-bottom: 0;
                line-height: 100%;
            }
            h4 {
                @include font-size(24);
                margin: 25px 0 8px;
                @include breakpoint(gamma) {
                    margin: 25px auto 8px;
                    max-width: 100px;
                }
            }
            h5 {
                letter-spacing: 0;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 15px;
            }
            .kc {
                min-height: 465px;
                @include breakpoint(grid-sm) {
                    max-width: 250px;
                    margin: 0 auto;
                }
                @include breakpoint(grid-sm) {
                    left: auto !important;                    
                }
            }
            .contacts {
                margin-top: 15px;
                text-align: center;
                @include font-size(14);                
                & > span, & > a {
                    display: block;
                }
                .social {
                    margin-top: 15px;
                    a {
                        display: inline-block;
                        margin: 0 10px;
                        padding: 4px 6px 2px;
                        border: 1px solid #333;
                        color: #333;
                        text-decoration: none;
                        font-weight: 400;
                        transition: all .2s linear;
                        &:hover {
                            background: #333;
                            color: #FFF;
                        }                        
                    }
                }
                @include breakpoint(beta) {
                    margin-bottom: 25px;
                }
            }
        }
        @include breakpoint(grid-sm) {
            padding: 25px 0;
        }
    }    
}