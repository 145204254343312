_:-ms-lang(x), .hero .intro {
    top: 50%;
    transform: translateY(-50%);
}

.ghost {
    @include breakpoint(gamma) {
        padding: 6px 6px;
        margin: 0 2px 15px;
        font-size: 1rem;
    }
}

.homepage_notice {
    display: flex;
    max-width: 1000px;
    justify-content: center;
    margin: auto;
    padding: 45px 0;
    gap: 25px;
    & > div {
        flex: 1;
    }
    & .text {
        padding: 0 45px;
    }
    & .image > img {
        width: 100%;
    }
    @include breakpoint(alpha) {
        flex-direction: column;
        align-items: center;                 
    }
}