// Site colours
$red:        #C42E38;
$grey:       #9A9A9B;
$dark_grey:  #999999;
$light_grey: #F8F8F8;
$mid_grey:   #ECECEC;

// Widths
$max_width: 1400px;

// Fonts
$default_font_colour: $grey;
$default_body_font: "Open Sans", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
$default_heading_font: "Libre Baskerville", Baskerville, "Baskerville Old Face", "Hoefler Text", Garamond, "Times New Roman", serif;
$default_font_size: 16;

// Browser vendor prefixes
$vendors: -webkit-, -moz-, -ms-, -o-;
