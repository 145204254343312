body {
    max-width: 1800px;
    margin: 0 auto;
}

.wrapper {
	@extend %center-block;
    width: 100%;
    max-width: $max_width;
    position: relative;
    @include breakpoint(wrapper) {
        padding: 0 50px;
    }
    @include breakpoint(gamma) {
        padding: 0 25px;    
    }
}

.hero {
    display: flex;
    align-items:  center;
    overflow: hidden;
    min-height: 150px;
    max-height: 920px;
    position: relative;    
    background: #333;

    @include breakpoint(grid-sm) {
        min-height: 100px;
    }
    
    @include breakpoint(grid-xs) {
        display: block;
    }
    
    @include breakpoint(gamma) {
        min-height: 250px;
    }

    header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 20;
        width: 100%;         
        border-top: 3px solid $dark_grey;
        
        .search, .burger {
            position: absolute;
            right: 0;
            top: 28px;
            width: 50px;
            height: 50px;
            display: block;
        }
        .search {
            right: 80px;
            @include breakpoint(wrapper) {
                right: 130px;    
            }
        }
        .burger {
            top: 25px;
            @include breakpoint(wrapper) {
                right: 50px;    
            }
        }
        &.dark {
            background: #333;
        }
        &.transparent {
            transition: background ease-in-out .25s;
        }
        @include breakpoint(grid-sm) {
            .logo {
                width: 100px;
            }
        }
        @include breakpoint(gamma) {
            .wrapper {
                padding: 0 25px;
            }
            .logo {
                width: 70px;
            }
            .search {
                height: 40px;
                width: 40px;
                top: 23px;
                right: 70px;
                svg {
                    height: 25px;
                }
            }
            .burger {
                height: 40px;
                width: 40px;
                top: 25px;
                right: 25px;
                svg {
                    height: 20px;
                }
            }
        }
    }
    nav {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 25;
        width: 100%;
        height: 100vh;
        background: #333;
        text-align: center;        
        h2 {
            padding: 25px 0 30px;
            text-transform: lowercase;
            letter-spacing: 7px;
            font-weight: 400;
            font-family: $default_body_font;
            @include font-size(16);
            @include breakpoint(gamma) {
                padding-bottom: 10px;  
                @include font-size(14);
            }
        }
        ul {
            margin-top: 30px;
            a {
                display: inline-block;
                text-align: center;
                text-decoration: none;
                padding: 30px 0;
                padding: 1.9vh 0;
                color: #FFF;
                font-family: $default_heading_font;
                @include font-size(52);
                font-size: 4vh;
                &:hover, &:active {
                    text-decoration: none;
                    color: $grey;
                }
                &.current {
                    color: $red;
                }
                &.sub {
                    padding-right: 40px;
                    background: url("/images/arrow.png") right 30px no-repeat;
                    background-size: 25px 27px;
                }
                @include breakpoint(gamma) {
                    @include font-size(28);  
                    padding: 10px 0;
                    &.sub {
                        padding-right: 30px;
                        background-position: right 16px;
                        background-size: 15px 16px;
                    }
                }
            }
            ul {
                display: none;
                margin: 10px 0 30px;
                a {
                    @include font-size(36);
                    font-size: 2.8vh;
                    padding: 15px 0;
                    padding: .8vh 0;
                    @include breakpoint(gamma) {
                        @include font-size(18);
                        padding: 3px 0;
                    }
                }
                @include breakpoint(gamma) {
                    margin-bottom: 20px;    
                }
            }
            @include breakpoint(gamma) {
                margin-top: 15px;   
            }
        }
        a.close-nav {
            position: absolute;
            right: 0;
            top: 35px;            
            width: 50px;
            height: 50px;
            display: block;
            background: url("/images/close.png");
            background-size: contain;
            background-repeat: no-repeat !important;
            @include breakpoint(wrapper) {
                right: 50px;    
            }
            @include breakpoint(gamma) {
                width: 40px;
                height: 40px;
                top: 30px;
                right: 30px;
            }
        }
    }
    .carousel {
        width: 100%;

        @include breakpoint(gamma) {
            height: 250px;
        }
        .item {

            @include breakpoint(gamma) {
                height: 100%;

                img {
                    width: auto;
                    height: 100%;
                }
            }
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .2);
            }
            img {
                width: 100%;
            }
        }
    }
    .carousel-fade {
        .carousel-inner {
            @include breakpoint(gamma) {
                height: 100%;
            }

            .item {
                transition-property: opacity;
            }

            .item,
            .active.left,
            .active.right {
                opacity: 0;
            }

            .active,
            .next.left,
            .prev.right {
                opacity: 1;
            }

            .next,
            .prev,
            .active.left,
            .active.right {
                left: 0;
                transform: translate3d(0, 0, 0);
            }
        }

        .carousel-control {
            z-index: 2;
        }
    }
    .carousel-indicators li {
        display: none;
    }
    .intro {
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        
        @include breakpoint(grid-xs) {
            top: 50px;
        }
        h1 {
            margin: 0 auto .75em;
            max-width: 800px;
            color: #FFF;
            text-transform: lowercase;
            text-shadow: 0 0 12px #444;
            font-weight: 400;
            @include font-size(96);
            @include breakpoint(grid-sm) {
                @include font-size(56);
                max-width: 470px;
            }
            @include breakpoint(beta) {
                @include font-size(40);    
            }
            @include breakpoint(grid-xs) {
                @include font-size(34);    
            }
            @include breakpoint(gamma) {
                @include font-size(26);
                padding-top: 35px;
                
            }
        }        
    }
    & > h2 {
        position: absolute;
        left: 0;
        bottom: 55px;
        width: 100%;
        font-weight: 400;
        @include font-size(24);
        text-transform: lowercase;
        text-align: center;
        color: #FFF;
        @include breakpoint(beta) {
            @include font-size(18);    
        }
        @include breakpoint(gamma) {
            bottom: 35px;
            @include font-size(16);
        }
    }
}

body.scrolling .hero .transparent {
    background: #333;
}

.ghost {
    display: inline-block;
    border: 1px solid #FFF;
    border-radius: 4px;
    padding: 6px 20px;
    margin: 0 15px;
    color: #FFF;
    letter-spacing: 1px;
    text-decoration: none;
    background: rgba(0, 0, 0, .3);
    transition: all .25s linear;
    &:hover {
        background: #FFF;
        color: #333;
        text-decoration: none;
    }
    @include breakpoint(gamma) {
        padding: 6px 6px;
        margin: 0 5px;
    }
}


footer {
    background: $mid_grey;     
    .social-media {
        padding: 50px;
        text-align: center;
        a {
            display: inline-block;
            margin: 0 15px;
            padding: 5px 8px 3px;
            border: 1px solid $grey;
            color: $grey;
            text-decoration: none;
            font-weight: 400;
            transition: all .2s linear;
            &:hover {
                background: $grey;
                color: #FFF;
            }
        }
        @include breakpoint(grid-sm) {
            padding: 25px 0 0;
        }
    }
    .addresses {
        max-width: 1000px;
        margin: 0 auto;
        .address {
            text-align: center;
            h3, p, a {
                @include font-size(14);
                max-width: none;
                margin: 0;
            }
            h3 {
                font-family: $default_body_font;
            }
            .ghost {
                background: none;
                margin-top: 20px;
                border: 1px solid $grey;
                color: $grey;
                transition: all .2s linear;
                &:hover {
                    background: $grey;
                    color: #FFF;
                }
            }
            @include breakpoint(grid-sm) {
                padding-top: 25px;
            }
        }
    }
    .info {
        margin-top: 50px;
        padding: 15px 50px;
        border-top: 1px solid $dark_grey;
        p {
            float: left;
            margin: 0;
            @include font-size(14);
            &:last-child {
                float: right;
            }
        }
        @include breakpoint(grid-sm) {
            margin-top: 25px;     
        }
    }
}

main.text-page .panel-section {
    padding: 50px !important;
    h2 {
        text-align: left;
        margin: 30px 0 15px;
        letter-spacing: 0;
        @include font-size(22);
        color: $red;
    }
    ul {
        margin: 0 0 20px 25px;
        li {
            list-style-type: disc;
        }
    }
}